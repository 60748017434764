import React from "react"
import PropTypes from "prop-types"
import ReactDropdown from "react-dropdown"

const Select = ({ id, name, options, value, placeholder, onChange, styles }) => {
  return (
    <div className={styles ? ('textfield ' + styles) : 'textfield'} id={id} name={name}>
      <ReactDropdown
        value={value}
        options={options}
        placeholder={placeholder}
        onChange={onChange}
      />
    </div>
  )
}

Select.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.any,
      label: PropTypes.string.isRequired,
    })
  ),
  placeholder: PropTypes.string,
  validators: PropTypes.arrayOf(PropTypes.func),
  errorsMessage: PropTypes.arrayOf(
    PropTypes.shape({
      validator: PropTypes.string,
      message: PropTypes.string,
    })
  ),
  onChange: PropTypes.func.isRequired,
  onError: PropTypes.func,
}

Select.defaultProps = {
  options: [],
  isValid: true,
  onError: () => {},
  validators: [],
  errorsMessage: [],
}

export default Select
