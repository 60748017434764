import React from "react"
import PropTypes from "prop-types"

const TextArea = ({ id, name, value, onChange, placeholder }) => {
  return (
    <textarea
      id={id}
      name={name}
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      className="textfield__field"
    ></textarea>
  )
}

TextArea.propTypes = {
  id: PropTypes.string.isRequired,
  value: PropTypes.any,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  onChange: PropTypes.func.isRequired,
}

export default TextArea
