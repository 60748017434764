import React from "react"
import PropTypes from "prop-types"
import { stack as Menu } from "react-burger-menu"
import { useUser } from "../../reducers/userReducer"
import { FormattedMessage, injectIntl, intlShape } from "react-intl"
import { Link } from "@reach/router"
import { getLocalizedPath } from "../../i18n"
import useAuth from "../../firebase/useAuth"
import LanguageSwitcher from "../LanguageSwitcher"
import { useMediaQuery } from "react-responsive"
import { Devices } from "../../constants"
import { SenzaLogo } from "../Logo"
import Logout from "../Icon/Logout/Logout"
const SideBar = ({ intl, locale }) => {
  const { userItem, removeUser, removeUserError, removeUserRequest } = useUser()
  const { signOut } = useAuth(removeUser, removeUserError)
  const handleClickLogout = () => {
    removeUserRequest()
    signOut()
  }
  const isMobile = useMediaQuery(Devices.MOBILE)
  return (
    <>
      {isMobile && userItem && userItem.sensaUser && (
        <Menu menuClassName="sidebar" right>
          <div className="sidebar__header">
            <div className="brand">
              <SenzaLogo />
            </div>
            <h1 className="mt-s c-grey03">
              <FormattedMessage
                id="member.survey.title"
                values={{ username: userItem.sensaUser.lastName }}
              />
            </h1>
          </div>
          <div className="sidebar__content">
            <Link className="sidebar__link" to={getLocalizedPath("/app/contact/", locale)}>
              <FormattedMessage id="link.contact" />
            </Link>
          </div>
          <div className="sidebar__footer">
            <div className="item">
              <LanguageSwitcher flat />
            </div>
            <div className="item">
              <div
                className="btn c-grey03"
                tabIndex="0"
                role="button"
                onClick={handleClickLogout}
                onKeyDown={handleClickLogout}
              >
                <span className="btn__icon">
                  <Logout />
                </span>
                <span className="btn__label">
                  <FormattedMessage id="logout" />
                </span>
              </div>
            </div>
          </div>
        </Menu>
      )}
    </>
  )
}

SideBar.propTypes = {
  intl: intlShape.isRequired,
  locale: PropTypes.string.isRequired,
}

export default injectIntl(SideBar)
