import React, { useState } from "react"
import PropTypes from "prop-types"
import { navigate } from "gatsby"

import { intlShape, injectIntl, FormattedMessage } from "react-intl"
import { getLocalizedPath } from "../../i18n"
import { Input, Validators } from "../Form"
import { useUser } from "../../reducers/userReducer"
import useAuth from "../../firebase/useAuth"

const LOGIN_USERNAME_VALIDATORS = [Validators.required]
const LOGIN_PASSWORD_VALIDATORS = [Validators.required]
const LOGIN_PASSWORD_MESSAGES = requiredMessage => [
  {
    validator: "required",
    message: requiredMessage,
  },
]
const LOGIN_USERNAME_MESSAGES = requiredMessage => [
  {
    validator: "required",
    message: requiredMessage,
  },
]

const Login = ({ intl, locale }) => {
  const { error, addUser, addUserRequest, addUserError } = useUser()
  const { signIn } = useAuth(user => {
    addUser(user)
    navigate(getLocalizedPath("/app/home", locale))
  }, addUserError)
  const [inputValues, setInputValues] = useState({
    username: ``,
    password: ``,
  })

  const handleUpdate = event => {
    const { name, value } = event.target
    setInputValues({ ...inputValues, [name]: value })
  }

  const handleSubmit = event => {
    event.preventDefault()
    login(inputValues.username, inputValues.password)
  }

  const login = (username, password) => {
    addUserRequest()
    signIn({ username, password, displayName: "Jhon Doe" })
  }

  return (
    <div className="login py-l">
      <div className="container">
        <div className="row">
          <div className="col-12 col-sm-6 offset-sm-3">
            <h1 className="text-center py-xl">
              <FormattedMessage id="login.title" />
            </h1>
            <form
              className="form-container"
              method="post"
              onSubmit={handleSubmit}
            >
              <Input
                id="username"
                name="username"
                value={inputValues.username}
                placeholder="login.field.username.placeholder"
                type="text"
                onChange={event => handleUpdate(event)}
                validators={LOGIN_USERNAME_VALIDATORS}
                errorsMessage={LOGIN_USERNAME_MESSAGES(
                  intl.formatMessage({
                    id: "field.required.validation.message",
                  })
                )}
              />
              <Input
                id="password"
                name="password"
                value={inputValues.password}
                placeholder="login.field.password.placeholder"
                type="password"
                onChange={event => handleUpdate(event)}
                validators={LOGIN_PASSWORD_VALIDATORS}
                errorsMessage={LOGIN_PASSWORD_MESSAGES(
                  intl.formatMessage({
                    id: "field.required.validation.message",
                  })
                )}
              />
              <div className="text-center pt-s">
                <button type="submit" className="button -primary">
                  <span className="button__label">
                    <FormattedMessage id="login.button" />
                  </span>
                </button>
              </div>
              {error && error.code (
                <div className="text-center pt-xs f-s c-warning02">
                  <FormattedMessage
                    id={`login.${error.code.replace("/", ".")}`}
                  />
                </div>
              )}
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

Login.propTypes = {
  intl: intlShape.isRequired,
  locale: PropTypes.string.isRequired,
}

export default injectIntl(Login)
