import React from "react"
import PropTypes from "prop-types"
import cn from "classnames"
import LanguageSwitcher from "../LanguageSwitcher"
import { SenzaLogo } from "../Logo"
import { Link } from "@reach/router"
import UserMenu from "../UserMenu/UserMenu"
import { useUser } from "../../reducers/userReducer"
import useAuth from "../../firebase/useAuth"
import { FormattedMessage } from "react-intl"
import { getLocalizedPath } from "../../i18n"
import { useMediaQuery } from "react-responsive"
import { Devices } from "../../constants"
const Header = ({ locale }) => {
  const { userItem, removeUser, removeUserError, removeUserRequest } = useUser()
  const { signOut } = useAuth(removeUser, removeUserError)
  const handleSelectionChange = value => {
    if (value === "logout") {
      removeUserRequest()
      signOut()
    }
  }
  const isMobile = useMediaQuery(Devices.MOBILE)
  return (
    <header className={cn("navigation", { "-dashboard": !!userItem })}>
      <div className="navigation__brand">
        <Link to="/">
          <SenzaLogo />
        </Link>
      </div>

      <div className="navigation__picture"></div>

      {!isMobile && (
        <nav className="navigation__nav mb-xs">
          {userItem && userItem.displayName && (
            <>
              <UserMenu
                label={userItem.displayName}
                onSelectionChange={handleSelectionChange}
              />
              <Link to={getLocalizedPath("/app/contact/", locale)}>
                <FormattedMessage id="link.contact" />
              </Link>
            </>
          )}
          <LanguageSwitcher />
        </nav>
      )}
    </header>
  )
}
Header.propTypes = {
  locale: PropTypes.string.isRequired,
}

export default Header
