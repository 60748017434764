module.exports = [
  {
    default: true,
    locale: "fr",
    label: "Fr",
    routes: {
      "/":"/",
      "/app": "/espace-connecte",
      "/app/home": "/espace-connecte/accueil",
      "/app/login": "/espace-connecte/connexion",
      "/app/profile": "/espace-connecte/profile",
      "/app/contact": "/espace-connecte/contact",
      "/app/dashboard": "/espace-connecte/tableau-de-bord",
      "/app/members": "/espace-connecte/membres",
      "/404": "/404.html",
    },
  },
  {
    locale: "nl",
    label: "Nl",
    routes: {
      "/":"/",
      "/app": "/connected-space",
      "/app/home": "/espace-connecte/home",
      "/app/login": "/connected-space/login",
      "/app/profile": "/connected-space/profile",
      "/app/contact": "/connected-space/contact",
      "/app/dashboard": "/connected-space/dashboard",
      "/app/members": "/connected-space/leden",
      "/404": "/404.html",
    },
  },
]
