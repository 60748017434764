const languages = require("../config/languages");

const defaultLanguage = languages.find(lang => lang.default);

if (!defaultLanguage) {
    throw new Error(
        "Default language has to be specified in the language configuration."
    );
}

const getOriginalPath = (path, locale) => {
    const lang = languages.find(lang => lang.locale === locale);
    let keyPath
    for (let [key, value] of Object.entries(lang.routes)) {
        if (value === path.replace(`/${locale}`, '')) {
            keyPath = key
        }
      }
    if (!keyPath) {
        throw new Error(
            `You have to specify a tranlastion for all of your routes.\n There is no key path for ${path} in language ${locale}.`
        );
    }
    return keyPath
}
module.exports = getOriginalPath;