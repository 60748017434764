import React from "react"

import Header from "../components/Header"

import { getDisplayName } from "../utils"
import SideBar from '../components/SideBar/SideBar'

const withLayout = Component => {
  const WrapperComponent = props => {
    return (
      <React.Fragment>
        <SideBar locale={props.pageContext.locale} />
        <Header locale={props.pageContext.locale} />
        <main id="page-wrap">
          <Component {...props} />
        </main>
      </React.Fragment>
    )
  }
  WrapperComponent.displayName = `Layout(${getDisplayName(Component)})`
  return WrapperComponent
}

export default withLayout
