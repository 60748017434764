import * as EmailValidator from 'email-validator';
import * as Phone from 'phone';

const Validators = {
    required: value => value !== null && value !== undefined && value !== '',
	email: value => EmailValidator.validate(value),
	phone: value => Phone(value)[0] === value,
	zipCode: value => (value >= 1000 && value <=9999)
};

export default Validators;
