import React, { Fragment, Component } from "react"
import PropTypes from "prop-types"
import cn from "classnames"
import Select from "../Select"
import TextArea from "../TextArea"
import { FormattedMessage, intlShape, injectIntl } from "react-intl"

const ERROR = "-error"
class Input extends Component {
  constructor(props) {
    super(props)
    this.inputRef = React.createRef()
    this.state = {
      isPristine: true,
      availableErrorsMessage: [...props.errorsMessage],
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.focus !== this.props.focus) {
      if (this.props.focus) {
        setTimeout(() => {
          this.focus()
        }, 500)
      } else {
        this.blur()
      }
    }
  }

  focus = () => {
    if (this.inputRef && this.inputRef.current) {
      this.inputRef.current.focus()
    }
  }

  blur = () => {
    if (this.inputRef && this.inputRef.current) {
      this.inputRef.current.blur()
    }
  }

  setPristine(value) {
    this.setState({ isPristine: value })
  }

  setValue(value) {
    this.props.onChange({ target: { value } }, this.props.id)
  }

  validate = value => {
    return this.props.validators
      .map(validator => {
        if (validator(value) === false) {
          return this.state.availableErrorsMessage.filter(
            errorMessage => errorMessage.validator === validator.name
          )[0]
        }
        return null
      })
      .filter(errorMessage => errorMessage)
  }

  handleChange = event => {
    this.props.onChange(event, this.props.id)
    this.props.onError(event, this.props.id, this.validate(event.target.value))
    if (this.state.isPristine === true) {
      this.setState({ isPristine: false })
    }
  }

  handleOnBlur = event => {
    this.props.onError(event, this.props.id, this.validate(event.target.value))
    this.setState({ isPristine: false })
  }

  handleOnClear = event => {
    this.props.onClear(event, this.props.id)
  }

  handleKeyDownClear = event => {
    if (event.key === "Enter") {
      this.handleOnClear(event)
    }
  }

  render = () => {
    const {
      intl,
      id,
      label,
      icon,
      rightText,
      type,
      name,
      value,
      placeholder,
      options,
      clearable,
    } = this.props
    const { isPristine } = this.state
    const errors = this.validate(value)
    const isValid = errors.length === 0
    const hasError = !isPristine && !isValid
    return (
      <Fragment>
        {label && (
          <label htmlFor={id}>
            <FormattedMessage id={label} />
          </label>
        )}
        <div className={cn("textfield", { [ERROR]: hasError })}>
          {(icon || rightText) && (
            <div className="textfield__icon">
              {icon ? (
                <span className={icon}></span>
              ) : (
                rightText && <span>{rightText}</span>
              )}
            </div>
          )}
          {type === "text" || type === "email" || type === "password" ? (
            <>
              <input
                ref={this.inputRef}
                type={type}
                name={name}
                onChange={this.handleChange}
                onBlur={this.handleOnBlur}
                value={value}
                placeholder={intl.formatMessage({
                  id: placeholder,
                })}
                className="textfield__field"
                id={id}
              ></input>
              {clearable && (
                <div
                  className="textfield__ghost-button"
                  role="button"
                  onClick={this.handleOnClear}
                  onKeyDown={this.handleKeyDownClear}
                  tabIndex={0}
                >
                  <span className="icon-cross"></span>
                </div>
              )}
            </>
          ) : type === "dropdown" ? (
            <Select
              id={id}
              name={name}
              placeholder={intl.formatMessage({
                id: placeholder,
              })}
              options={options}
              value={value}
              onBlur={this.handleOnBlur}
              onChange={this.handleChange}
            />
          ) : (
            <TextArea
              id={id}
              name={name}
              placeholder={intl.formatMessage({
                id: placeholder,
              })}
              value={value}
              onChange={this.handleChange}
              onBlur={this.handleOnBlur}
            />
          )}
        </div>
        {hasError && (
          <div className="error">
            <FormattedMessage id={errors[0].message} />
          </div>
        )}
      </Fragment>
    )
  }
}

export const InputPropTypes = {
  intl: intlShape,
  type: PropTypes.string.isRequired,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  value: PropTypes.any,
  name: PropTypes.string.isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  icon: PropTypes.string,
  rightText: PropTypes.string,
  placeholder: PropTypes.string,
  validators: PropTypes.arrayOf(PropTypes.func),
  clearable: PropTypes.bool,
  errorsMessage: PropTypes.arrayOf(
    PropTypes.shape({
      validator: PropTypes.string,
      message: PropTypes.string,
    })
  ),
  focus: PropTypes.bool,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.any,
      label: PropTypes.string,
    })
  ),
  onChange: PropTypes.func,
  onClear: PropTypes.func,
  onError: PropTypes.func,
}

Input.propTypes = InputPropTypes

Input.defaultProps = {
  isValid: true,
  clearable: false,
  onClear: () => {},
  onError: () => {},
  validators: [],
  errorsMessage: [],
}
export default injectIntl(Input, { withRef: true })
