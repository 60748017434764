import React from "react"
import { navigate } from "gatsby"

import { globalHistory } from "@reach/router"

import Dropdown from "react-dropdown"
import PropTypes from "prop-types"
import { languages, getOriginalPath, getLocalizedPath } from "../../i18n"

import { PageContext } from "../../pageContext"

import "react-dropdown/style.css"

const options = languages.map(lang => ({
  value: lang.locale,
  label: lang.label,
}))

const onSelect = option => {
  let path = option.originalPath
  if (option.matchPath) {
    path = getOriginalPath(globalHistory.location.pathname, option.locale)
  }
  navigate(getLocalizedPath(path, option.value))
}

const LanguageSwitcher = ({ flat }) => {
  return (
    <PageContext.Consumer>
      {({ originalPath, locale, matchPath }) => (
        <>
          {flat ? (
            options.map(option => (
              <span
                role="button"
                tabIndex="0"
                onKeyDown={() => {
                  onSelect({
                    ...option,
                    matchPath,
                    locale,
                    originalPath,
                  })
                }}
                className="flat-item c-grey03 mr-m"
                key={option.value}
                onClick={() => {
                  onSelect({
                    ...option,
                    matchPath,
                    locale,
                    originalPath,
                  })
                }}
              >
                {option.value.toUpperCase()}
              </span>
            ))
          ) : (
            <Dropdown
              value={locale}
              options={options}
              onChange={item =>
                onSelect({ ...item, matchPath, locale, originalPath })
              }
            />
          )}
        </>
      )}
    </PageContext.Consumer>
  )
}
LanguageSwitcher.propsType = {
  flat: PropTypes.bool,
}
LanguageSwitcher.defaultProps = {
  flat: false,
}
export default LanguageSwitcher
