import React from "react"

const SenzaLogo = () => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 225 71"
    className="logo"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xlink="http://www.w3.org/1999/xlink"
  >
    <g
      id="Page-1"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g id="sensa-color-baseline" fillRule="nonzero">
        <path
          d="M15.46,45 C16.4180717,45.7587409 17.7941982,45.6724634 18.65,44.8 L35.35,27.8 C35.7417257,27.4247229 36.2187275,27.1501894 36.74,27 C37.21,26.88 37.66,26.89 37.8,27.12 L40.11,30.85 C40.3681421,31.1722825 40.7462927,31.3756734 41.1574865,31.4133976 C41.5686802,31.4511218 41.9775262,31.3199325 42.29,31.05 L56.9,18.05 L56.9,18.05 L72.1700117,4.31 C72.1700117,4.31 72.1700117,4.31 72.1700117,4.31 C72.1726553,3.16956816 71.7221654,2.07479097 70.9176336,1.26650851 C70.1131019,0.45822605 69.0204319,0.00264908318 67.88,0 L32,0 C18,0 6.18,11 6,25 C5.91380024,32.7658591 9.4019454,40.1403732 15.46,45 L15.46,45 Z"
          id="Path"
          fill="#05E5BA"
        ></path>
        <path
          d="M56.72,25.75 C55.7651457,24.9926213 54.3924377,25.0789551 53.54,25.95 L36.83,42.95 C36.4455378,43.3340843 35.9711569,43.6159628 35.45,43.77 C34.98,43.9 34.52,43.88 34.38,43.65 L32.07,39.9 C31.8118579,39.5777175 31.4337073,39.3743266 31.0225135,39.3366024 C30.6113198,39.2988782 30.2024738,39.4300675 29.89,39.7 L15.28,52.7 L15.28,52.7 L0,66.48 L0,66.48 C0.00551377826,68.8509182 1.92907534,70.77 4.3,70.77 L40.24,70.77 C54.24,70.77 66,59.77 66.17,45.77 C66.2693063,37.9978669 62.7834978,30.6131171 56.72,25.75 Z"
          id="Path"
          fill="#02C09E"
        ></path>
        <path
          d="M89.2,46.67 C85.42,46.67 79.36,45.41 77.56,42.83 C77.1976654,42.3676865 77.0005238,41.7973839 77,41.21 C77.016184,39.4477998 78.4378588,38.0216822 80.2,38 C80.7492184,37.9899561 81.2900635,38.1355683 81.76,38.42 C84.1292465,39.8267475 86.8076488,40.6302682 89.56,40.76 C92.86,40.76 94.42,39.44 94.42,37.64 C94.42,32.84 77.2,36.74 77.2,25.34 C77.2,20.48 81.46,16.22 89.08,16.22 C92.86,16.22 98.02,17.42 99.64,19.76 C99.9494664,20.1992301 100.116979,20.7227073 100.12,21.26 C100.055556,22.8698118 98.7311006,24.1412894 97.12,24.14 C96.6174227,24.1425145 96.1222673,24.0187256 95.68,23.78 C93.6866712,22.6453701 91.4336236,22.0459355 89.14,22.04 C86.38,22.04 84.58,23.3 84.58,24.92 C84.58,29.24 101.74,25.64 101.74,37.34 C101.74,42.65 97.24,46.67 89.2,46.67 Z"
          id="Path"
          fill="#000000"
        ></path>
        <path
          d="M130.18,33.89 L112.3,33.89 C112.78,37.55 115.72,40.61 120.64,40.61 C122.605254,40.5666742 124.534517,40.0740963 126.28,39.17 C126.7463,38.9379946 127.259194,38.8148999 127.78,38.81 C129.503848,38.792359 130.929836,40.1474901 131,41.87 C131,45.29 123.62,46.67 119.78,46.67 C110.96,46.67 104.3,40.73 104.3,31.43 C104.3,23.03 110.48,16.25 119.3,16.25 C125.500993,16.0867176 131.052155,20.0722723 132.88,26 C133.37215,27.4112102 133.655148,28.8868423 133.72,30.38 C133.708984,32.3256575 132.125681,33.8955431 130.18,33.89 L130.18,33.89 Z M119.32,22.31 C115.616041,22.223245 112.485968,25.0376809 112.18,28.73 L126.46,28.73 C126.385702,26.9241765 125.586064,25.2246834 124.242109,24.0162532 C122.898153,22.807823 121.123538,22.1926509 119.32,22.31 L119.32,22.31 Z"
          id="Shape"
          fill="#000000"
        ></path>
        <path
          d="M161.5,46.31 C160.495849,46.3153556 159.531277,45.9188268 158.821225,45.208775 C158.111173,44.4987232 157.714644,43.5341506 157.72,42.53 L157.72,28.43 C157.72,24.43 155.62,23.03 152.38,23.03 C149.761955,23.0581376 147.312684,24.3273053 145.78,26.45 L145.78,42.53 C145.78,44.6342049 144.074205,46.34 141.97,46.34 C139.865795,46.34 138.16,44.6342049 138.16,42.53 L138.16,20.33 C138.17557,19.3167882 138.597301,18.3522539 139.330556,17.6528414 C140.063811,16.953429 141.047183,16.5777111 142.06,16.61 C144.109953,16.6209333 145.769067,18.2800465 145.78,20.33 L145.78,20.75 C148.340779,17.8413474 152.04507,16.1974312 155.92,16.25 C162.28,16.25 165.340217,19.85 165.340217,25.49 L165.340217,42.49 C165.350923,43.5099188 164.949206,44.4909656 164.226093,45.2103129 C163.502979,45.9296602 162.519848,46.3262486 161.5,46.31 L161.5,46.31 Z"
          id="Path"
          fill="#000000"
        ></path>
        <path
          d="M182.14,46.67 C178.36,46.67 172.3,45.41 170.5,42.83 C170.144823,42.3650939 169.954802,41.7950313 169.959896,41.21 C169.975947,39.4554515 171.385679,38.0324205 173.14,38 C173.689218,37.9899561 174.230064,38.1355683 174.7,38.42 C177.069246,39.8267475 179.747649,40.6302682 182.5,40.76 C185.8,40.76 187.36,39.44 187.36,37.64 C187.36,32.84 170.14,36.74 170.14,25.34 C170.14,20.48 174.4,16.22 182.02,16.22 C185.8,16.22 190.96,17.42 192.58,19.76 C192.889466,20.1992301 193.056979,20.7227073 193.06,21.26 C192.995556,22.8698118 191.671101,24.1412894 190.06,24.14 C189.557423,24.1425145 189.062267,24.0187256 188.62,23.78 C186.626671,22.6453701 184.373624,22.0459355 182.08,22.04 C179.32,22.04 177.52,23.3 177.52,24.92 C177.52,29.24 194.68,25.64 194.68,37.34 C194.68,42.65 190.18,46.67 182.14,46.67 Z"
          id="Path"
          fill="#000000"
        ></path>
        <path
          d="M220.42,46.31 L219.82,46.31 C218.936818,46.3020792 218.092959,45.9436217 217.474081,45.3134916 C216.855204,44.6833615 216.512008,43.8331816 216.52,42.95 C214.52,45.29 211.12,46.67 207.34,46.67 C202.72,46.67 197.26,43.55 197.26,37.07 C197.26,30.29 202.72,27.71 207.34,27.71 C211.18,27.71 214.6,28.97 216.52,31.25 L216.52,27.35 C216.52,24.41 214,22.49 210.16,22.49 C207.862366,22.4462657 205.604652,23.0943132 203.68,24.35 C203.263627,24.6060218 202.788405,24.7506545 202.3,24.77 C200.697466,24.7426592 199.414297,23.4327575 199.42,21.83 C199.430926,21.3025317 199.575429,20.7864477 199.84,20.33 C201.64,17.45 208.12,16.25 211.48,16.25 C218.08,16.25 224.140014,18.89 224.140014,27.25 L224.140014,42.55 C224.145568,44.6133061 222.483248,46.293501 220.42,46.31 L220.42,46.31 Z M216.52,35.39 C215.26,33.71 212.86,32.87 210.4,32.87 C207.4,32.87 204.94,34.43 204.94,37.25 C204.94,40.07 207.4,41.51 210.4,41.51 C212.86,41.51 215.26,40.67 216.52,38.99 L216.52,35.39 Z"
          id="Shape"
          fill="#000000"
        ></path>
        <g
          id="Group"
          opacity="0.5"
          transform="translate(77.000000, 52.000000)"
          fill="#000000"
        >
          <path
            d="M3.58,10.79 C2.58,10.79 1.12,10.48 0.67,9.79 C0.581283421,9.67532639 0.532158333,9.534969 0.53,9.39 C0.540543976,8.9526141 0.892614095,8.60054398 1.33,8.59 C1.4680018,8.58741466 1.603688,8.62568513 1.72,8.7 C2.32740824,9.05815842 3.01521531,9.25762247 3.72,9.28 C4.54,9.28 4.93,8.95 4.93,8.5 C4.93,7.3 0.63,8.28 0.63,5.43 C0.63,4.21 1.69,3.15 3.63,3.15 C4.5908462,3.08968507 5.537512,3.40524033 6.27,4.03 C6.34366285,4.13975805 6.38522263,4.26790072 6.39,4.4 C6.3791593,4.80638259 6.04652714,5.13014457 5.64,5.13 C5.51279933,5.13261728 5.3876272,5.09784724 5.28,5.03 C4.77761108,4.75248259 4.21392384,4.60468655 3.64,4.6 C2.95,4.6 2.5,4.92 2.5,5.32 C2.5,6.4 6.79,5.5 6.79,8.43 C6.71,9.78 5.59,10.79 3.58,10.79 Z"
            id="Path"
          ></path>
          <path
            d="M17.68,10.7 C17.4204738,10.7165999 17.1656671,10.6249423 16.9761826,10.446827 C16.7866982,10.2687116 16.6794697,10.020056 16.68,9.76 L16.68,6 C16.68,5.33 16.38,4.85 15.6,4.85 C14.9960159,4.87954881 14.4407958,5.19047206 14.1,5.69 L14.1,9.69 C13.9755556,10.1166667 13.5844444,10.41 13.14,10.41 C12.6955556,10.41 12.3044444,10.1166667 12.18,9.69 L12.18,6 C12.18,5.33 11.88,4.85 11.1,4.85 C10.4928338,4.88568317 9.93754565,5.20404836 9.6,5.71 L9.6,9.71 C9.46511641,10.1203745 9.0819732,10.3977501 8.65,10.3977501 C8.2180268,10.3977501 7.83488359,10.1203745 7.7,9.71 L7.7,4.21 C7.70222075,3.95168486 7.81064953,3.70566795 7.99981338,3.52974557 C8.18897723,3.35382319 8.4422027,3.26350164 8.7,3.28 C9.21362482,3.28 9.63,3.69637518 9.63,4.21 L9.63,4.31 C10.2222281,3.61328282 11.0857149,3.20522157 12,3.19 C12.8940973,3.1050539 13.7265954,3.65450261 14,4.51 C14.5670766,3.69251956 15.4951436,3.20053219 16.49,3.19 C17.0840272,3.1220516 17.6760042,3.33325415 18.0928718,3.76186444 C18.5097393,4.19047472 18.7044198,4.78808901 18.62,5.38 L18.62,9.76 C18.5904274,10.2663929 18.1863929,10.6704274 17.68,10.7 Z"
            id="Path"
          ></path>
          <path
            d="M25.48,10.7 L25.33,10.7 C24.8699908,10.6945233 24.4999674,10.3200418 24.5,9.86 C23.9057627,10.484654 23.0715052,10.8234573 22.21,10.79 C21.5537241,10.8199889 20.9136496,10.580761 20.4379194,10.1276846 C19.9621892,9.67460825 19.6920412,9.04695757 19.69,8.39 C19.6787532,7.73397156 19.9470146,7.10411163 20.4278186,6.65765077 C20.9086226,6.2111899 21.5566009,5.99025589 22.21,6.05 C23.0666452,5.9982127 23.9031528,6.32331827 24.5,6.94 L24.5,6 C24.5,5.27 23.87,4.78 22.91,4.78 C22.3351946,4.77147371 21.77099,4.93516271 21.29,5.25 C21.1885002,5.31632278 21.0711133,5.35430089 20.95,5.36 C20.6893591,5.35270919 20.4517906,5.20878616 20.3246406,4.98114669 C20.1974906,4.75350722 20.1995266,4.47575106 20.33,4.25 C20.78,3.53 22.4,3.25 23.24,3.25 C24.89,3.25 26.41,3.91 26.41,5.99 L26.41,9.82 C26.378566,10.3117478 25.9727332,10.6957616 25.48,10.7 L25.48,10.7 Z M24.48,7.97 C24.0939026,7.53605268 23.5297125,7.30373909 22.95,7.34 C22.2,7.34 21.59,7.73 21.59,8.44 C21.59,9.15 22.2,9.5 22.95,9.5 C23.5297125,9.53626091 24.0939026,9.30394732 24.48,8.87 L24.48,7.97 Z"
            id="Shape"
          ></path>
          <path
            d="M31.31,5.06 C30.737098,5.11292736 30.2068214,5.38523155 29.83,5.82 L29.83,9.76 C29.8300292,10.2752674 29.4152382,10.6945184 28.899998,10.7 L28.899998,10.7 C28.6404738,10.7165999 28.3856671,10.6249423 28.1961826,10.446827 C28.0066982,10.2687116 27.8994697,10.020056 27.899998,9.76 L27.899998,4.21 C27.9022207,3.95168486 28.0106495,3.70566795 28.1998134,3.52974557 C28.3889772,3.35382319 28.6422027,3.26350164 28.899998,3.28 C29.4136248,3.28 29.83,3.69637518 29.83,4.21 L29.83,4.36 C30.1646005,3.80065966 30.742043,3.43050422 31.39,3.36 C31.6085546,3.33675243 31.8264714,3.40925891 31.9875302,3.55881358 C32.1485891,3.70836825 32.2370168,3.9203245 32.23,4.14 C32.1920264,4.63151476 31.8015148,5.02202635 31.31,5.06 Z"
            id="Path"
          ></path>
          <path
            d="M35.38,10.79 C34.8234418,10.871457 34.2606449,10.6895336 33.8570723,10.2977155 C33.4534996,9.90589745 33.255025,9.34872224 33.32,8.79 L33.32,5 L32.86,5 C32.4340497,4.95331635 32.111515,4.59350085 32.111515,4.165 C32.111515,3.73649915 32.4340497,3.37668365 32.86,3.33 L33.32,3.33 L33.32,2.24 C33.3517798,1.71127612 33.7903227,1.29904577 34.32,1.29999835 C34.8391477,1.29999835 35.26,1.72085234 35.26,2.24 L35.26,3.36 L36,3.36 C36.3843377,3.44540203 36.6577913,3.78628818 36.6577913,4.18 C36.6577913,4.57371182 36.3843377,4.91459797 36,5 L35.25,5 L35.25,8.29999835 C35.25,8.87 35.61,9.1 35.91,9.1 L36.08,9.1 C36.3041942,9.08236852 36.525089,9.1623597 36.686012,9.31945116 C36.8469349,9.47654262 36.9322249,9.69544605 36.92,9.92 C36.91,10.4 36.62,10.79 35.38,10.79 Z"
            id="Path"
          ></path>
          <path
            d="M47.11,7.6 L42.64,7.6 C42.8021334,8.60925801 43.6992201,9.33382803 44.72,9.28 C45.2121047,9.26607484 45.6944642,9.13949823 46.13,8.91 C46.2499244,8.85650198 46.3788199,8.82597411 46.51,8.82 C46.9442417,8.81426746 47.3037524,9.15602455 47.32,9.59 C47.32,10.44 45.47,10.79 44.51,10.79 C43.1523907,10.811436 41.8864721,10.1069677 41.1891034,8.94196155 C40.4917347,7.77695542 40.4688628,6.32840357 41.1291034,5.1419615 C41.789344,3.95551942 43.0323907,3.2114359 44.39,3.19 C45.9398978,3.14920057 47.3266415,4.14732877 47.78,5.63 C47.9063221,5.9836391 47.9771603,6.35469608 47.99,6.73 C47.9792432,7.20984443 47.589936,7.59472769 47.11,7.6 L47.11,7.6 Z M44.39,4.7 C43.4622523,4.67609459 42.6790296,5.38451518 42.61,6.31 L46.18,6.31 C46.1621055,5.85697634 45.9618703,5.43041657 45.6247843,5.12722745 C45.2876982,4.82403832 44.8423807,4.66996133 44.39,4.7 L44.39,4.7 Z"
            id="Shape"
          ></path>
          <path
            d="M54.86,10.7 C54.3408523,10.7 53.92,10.2791477 53.92,9.76 L53.92,6.23 C53.9782286,5.85895318 53.8563408,5.48234862 53.5917491,5.21578238 C53.3271574,4.94921614 52.9514695,4.82453168 52.58,4.88 C51.9244915,4.88779659 51.3118303,5.20712305 50.93,5.74 L50.93,9.74 C50.7951164,10.1503745 50.4119732,10.4277501 49.98,10.4277501 C49.5480268,10.4277501 49.1648836,10.1503745 49.03,9.74 L49.03,4.21 C49.0322207,3.95168486 49.1406495,3.70566795 49.3298134,3.52974557 C49.5189772,3.35382319 49.7722027,3.26350164 50.03,3.28 C50.5436248,3.28 50.96,3.69637518 50.96,4.21 L50.96,4.31 C51.6040774,3.58584009 52.5309396,3.17714495 53.5,3.19 C54.1353898,3.11036054 54.7723957,3.32498287 55.2300419,3.77289194 C55.6876882,4.22080101 55.9159583,4.85304467 55.85,5.49 L55.85,9.76 C55.8503656,10.0182134 55.7444964,10.2652074 55.5572445,10.4430021 C55.3699925,10.6207969 55.1178481,10.713735 54.86,10.7 L54.86,10.7 Z"
            id="Path"
          ></path>
          <path
            d="M63.34,7.6 L58.87,7.6 C59.0336658,8.62952963 59.9606698,9.36068767 61,9.28 C61.4921047,9.26607484 61.9744642,9.13949823 62.41,8.91 C62.5299244,8.85650198 62.6588199,8.82597411 62.79,8.82 C63.2242417,8.81426746 63.5837524,9.15602455 63.6,9.59 C63.6,10.44 61.75,10.79 60.79,10.79 C59.4323907,10.811436 58.1664721,10.1069677 57.4691034,8.94196155 C56.7717347,7.77695542 56.7488628,6.32840357 57.4091034,5.1419615 C58.069344,3.95551942 59.3123907,3.2114359 60.67,3.19 C62.1978406,3.17622285 63.5527631,4.16901892 64,5.63 C64.1263221,5.9836391 64.1971603,6.35469608 64.21,6.73 C64.1994175,7.20603544 63.8160354,7.58941754 63.34,7.6 L63.34,7.6 Z M60.62,4.7 C59.6922523,4.67609459 58.9090296,5.38451518 58.84,6.31 L62.41,6.31 C62.3921055,5.85697634 62.1918703,5.43041657 61.8547843,5.12722745 C61.5176982,4.82403832 61.0723807,4.66996133 60.62,4.7 L60.62,4.7 Z"
            id="Shape"
          ></path>
          <path
            d="M68.65,5.06 C68.0741248,5.11245305 67.5405521,5.38461094 67.16,5.82 L67.16,9.76 C67.1600292,10.2752674 66.7452382,10.6945184 66.23,10.7 L66.23,10.7 C65.7108523,10.7 65.29,10.2791477 65.29,9.76 L65.29,4.21 C65.2922207,3.95168486 65.4006495,3.70566795 65.5898134,3.52974557 C65.7789772,3.35382319 66.0322027,3.26350164 66.29,3.28 C66.8036248,3.28 67.22,3.69637518 67.22,4.21 L67.22,4.36 C67.5546005,3.80065966 68.132043,3.43050422 68.78,3.36 C68.9993163,3.33323889 69.2192744,3.40454217 69.38118,3.55488308 C69.5430856,3.70522399 69.6304644,3.91930494 69.62,4.14 C69.5792944,4.64949481 69.1609344,5.04628993 68.65,5.06 Z"
            id="Path"
          ></path>
          <path
            d="M73.25,13.55 C72.37,13.55 70.88,13.32 70.45,12.65 C70.3697628,12.5247589 70.3280392,12.3787263 70.33,12.23 C70.3369125,11.9472522 70.4908073,11.6885862 70.7359964,11.5476025 C70.9811855,11.4066188 71.2821563,11.403737 71.53,11.54 C72.0480083,11.85257 72.645287,12.0088347 73.25,11.99 C73.7677174,12.0548223 74.2878067,11.8892318 74.6727434,11.5370147 C75.0576802,11.1847975 75.2686985,10.6814246 75.25,10.16 L75.25,9.43 C74.7212119,10.1429302 73.8876171,10.5652849 73,10.57 C71.19,10.57 69.81,9.29 69.81,6.88 C69.81,4.47 71.17,3.18997196 73,3.18997196 C73.8799305,3.18606029 74.7101529,3.59746514 75.24,4.3 L75.24,4.22 C75.3644444,3.79333333 75.7555556,3.5 76.2,3.5 C76.6444444,3.5 77.0355556,3.79333333 77.1600344,4.22 L77.1600344,10.11 C77.17,12.87 75,13.55 73.25,13.55 Z M75.25,5.7 C74.8568701,5.19101315 74.2530674,4.88911178 73.61,4.88 C72.5054305,4.88 71.61,5.7754305 71.61,6.88 C71.61,7.9845695 72.5054305,8.88 73.61,8.88 C74.2520072,8.86656715 74.8540494,8.56554608 75.25,8.06 L75.25,5.7 Z"
            id="Shape"
          ></path>
          <path
            d="M85,4.55 L82.08,11.79 C81.7364727,12.8671179 80.7200124,13.5855798 79.59,13.55 C78.93,13.55 78.42,13.22 78.42,12.69 C78.4250306,12.459121 78.5237854,12.2402267 78.6935486,12.0836672 C78.8633119,11.9271077 79.0894693,11.8463609 79.32,11.86 C79.3997099,11.8696348 79.4802901,11.8696348 79.56,11.86 C79.8698323,11.8201948 80.1396498,11.6290741 80.28,11.35 L80.55,10.74 L78.07,4.55 C78.0308143,4.45132093 78.0104632,4.34617376 78.01,4.24 C78.0417798,3.71127612 78.4803227,3.29904577 79.01,3.29994497 C79.4027158,3.31384847 79.7509193,3.5564172 79.9,3.92 L81.58,8.42 L83.26,3.92 C83.4119909,3.54989893 83.7699502,3.30601459 84.17,3.29994497 C84.4174706,3.29729516 84.6555946,3.39440821 84.8305932,3.56940681 C85.0055918,3.74440542 85.1027048,3.98252938 85.100055,4.23 C85.0890607,4.34241531 85.0550159,4.45135863 85,4.55 Z"
            id="Path"
          ></path>
          <path
            d="M94.79,10.7 L94.64,10.7 C94.4198652,10.6973638 94.2097957,10.6073791 94.0560124,10.449845 C93.9022292,10.2923109 93.8173318,10.0801344 93.82,9.86 C93.2234284,10.4873329 92.38494,10.8263739 91.52,10.79 C90.8644892,10.8171284 90.2260546,10.5769469 89.7509681,10.1244835 C89.2758815,9.67202014 89.004861,9.04605392 89,8.39 C88.9919096,7.73495754 89.2611291,7.10702537 89.7411765,6.66126702 C90.221224,6.21550866 90.8673506,5.99347656 91.52,6.05 C92.3787988,6.00325757 93.2163553,6.32735553 93.82,6.94 L93.82,6 C93.82,5.27 93.19,4.78 92.23,4.78 C91.6550292,4.77023907 91.090469,4.93403122 90.61,5.25 C90.5040669,5.31469857 90.383899,5.3524656 90.26,5.36 C89.8591405,5.34916203 89.5398535,5.02100594 89.54,4.62 C89.5428041,4.48903093 89.5807991,4.36122948 89.65,4.25 C90.1,3.53 91.72,3.25 92.56,3.25 C94.21,3.25 95.72,3.91 95.72,5.99 L95.72,9.82 C95.688566,10.3117478 95.2827332,10.6957616 94.79,10.7 L94.79,10.7 Z M93.79,7.97 C93.4012938,7.53974399 92.8389657,7.30819715 92.26,7.34 C91.51,7.34 90.89,7.73 90.89,8.44 C90.89,9.15 91.51,9.5 92.26,9.5 C92.8389657,9.53180285 93.4012938,9.30025601 93.79,8.87 L93.79,7.97 Z"
            id="Shape"
          ></path>
          <path
            d="M99.85,10.79 C98.9,10.79 97.39,10.48 96.94,9.79 C96.8512834,9.67532639 96.8021583,9.534969 96.8,9.39 C96.810544,8.9526141 97.1626141,8.60054398 97.6,8.59 C97.7380018,8.58741466 97.873688,8.62568513 97.99,8.7 C98.5826461,9.05021677 99.2522904,9.24939302 99.94,9.28 C100.76,9.28 101.15,8.95 101.15,8.5 C101.15,7.3 96.85,8.28 96.85,5.43 C96.85,4.21 97.91,3.15 99.85,3.15 C100.810846,3.08968507 101.757512,3.40524033 102.49,4.03 C102.563663,4.13975805 102.605223,4.26790072 102.61,4.4 C102.599159,4.80638259 102.266527,5.13014457 101.86,5.13 C101.732799,5.13261728 101.607627,5.09784724 101.5,5.03 C100.997611,4.75248259 100.433924,4.60468655 99.86,4.6 C99.17,4.6 98.72,4.92 98.72,5.32 C98.72,6.4 103.01,5.5 103.01,8.43 C103,9.78 101.86,10.79 99.85,10.79 Z"
            id="Path"
          ></path>
          <path
            d="M106.63,10.79 C105.68,10.79 104.17,10.48 103.72,9.79 C103.631283,9.67532639 103.582158,9.534969 103.58,9.39 C103.590544,8.9526141 103.942614,8.60054398 104.38,8.59 C104.518002,8.58741466 104.653688,8.62568513 104.77,8.7 C105.362646,9.05021677 106.03229,9.24939302 106.72,9.28 C107.54,9.28 107.93,8.95 107.93,8.5 C107.93,7.3 103.63,8.28 103.63,5.43 C103.63,4.21 104.69,3.15 106.63,3.15 C107.590846,3.08968507 108.537512,3.40524033 109.27,4.03 C109.343663,4.13975805 109.385223,4.26790072 109.39,4.4 C109.379159,4.80638259 109.046527,5.13014457 108.64,5.13 C108.512799,5.13261728 108.387627,5.09784724 108.28,5.03 C107.777611,4.75248259 107.213924,4.60468655 106.64,4.6 C105.95,4.6 105.5,4.92 105.5,5.32 C105.5,6.4 109.79,5.5 109.79,8.43 C109.76,9.78 108.64,10.79 106.63,10.79 Z"
            id="Path"
          ></path>
          <path
            d="M112.88,1.44 C112.882667,1.74407571 112.763739,2.03661481 112.549658,2.25257398 C112.335577,2.46853316 112.044087,2.59 111.74,2.59 C111.108781,2.58451093 110.599976,2.07124263 110.6,1.44 C110.61091,0.818219589 111.118124,0.31990429 111.74,0.32 C112.039698,0.317336068 112.328178,0.433841929 112.541972,0.643885195 C112.755766,0.85392846 112.87736,1.14030167 112.88,1.44 L112.88,1.44 Z M111.74,10.7 C111.220852,10.7 110.8,10.2791477 110.8,9.76 L110.8,4.21 C110.802221,3.95168486 110.91065,3.70566795 111.099813,3.52974557 C111.288977,3.35382319 111.542203,3.26350164 111.8,3.28 C112.046651,3.28 112.2832,3.37798186 112.457609,3.55239069 C112.632018,3.72679953 112.730001,3.96334866 112.730001,4.21 L112.730001,9.76 C112.730366,10.0182134 112.624496,10.2652074 112.437244,10.4430021 C112.249993,10.6207969 111.997848,10.713735 111.74,10.7 L111.74,10.7 Z"
            id="Shape"
          ></path>
          <path
            d="M116.83,10.79 C115.83,10.79 114.37,10.48 113.92,9.79 C113.831283,9.67532639 113.782158,9.534969 113.78,9.39 C113.790544,8.9526141 114.142614,8.60054398 114.58,8.59 C114.718002,8.58741466 114.853688,8.62568513 114.97,8.7 C115.562646,9.05021677 116.23229,9.24939302 116.92,9.28 C117.74,9.28 118.13,8.95 118.13,8.5 C118.13,7.3 113.83,8.28 113.83,5.43 C113.83,4.21 114.89,3.15 116.83,3.15 C117.790846,3.08968507 118.737512,3.40524033 119.47,4.03 C119.543663,4.13975805 119.585223,4.26790072 119.59,4.4 C119.579159,4.80638259 119.246527,5.13014457 118.84,5.13 C118.712799,5.13261728 118.587627,5.09784724 118.48,5.03 C117.977611,4.75248259 117.413924,4.60468655 116.84,4.6 C116.15,4.6 115.7,4.92 115.7,5.32 C115.7,6.4 119.99,5.5 119.99,8.43 C120,9.78 118.84,10.79 116.83,10.79 Z"
            id="Path"
          ></path>
          <path
            d="M123.43,10.79 C122.873442,10.871457 122.310645,10.6895336 121.907072,10.2977155 C121.5035,9.90589745 121.305025,9.34872224 121.37,8.79 L121.37,5 L120.91,5 C120.48405,4.95331635 120.161515,4.59350085 120.161515,4.165 C120.161515,3.73649915 120.48405,3.37668365 120.91,3.33 L121.37,3.33 L121.37,2.24 C121.40178,1.71127612 121.840323,1.29904577 122.37,1.29999835 C122.889148,1.29999835 123.31,1.72085234 123.31,2.24 L123.31,3.36 L124,3.36 C124.384338,3.44540203 124.657791,3.78628818 124.657791,4.18 C124.657791,4.57371182 124.384338,4.91459797 124,5 L123.25,5 L123.25,8.29999835 C123.25,8.87 123.61,9.1 123.91,9.1 L124.08,9.1 C124.304194,9.08236852 124.525089,9.1623597 124.686012,9.31945116 C124.846935,9.47654262 124.932225,9.69544605 124.92,9.92 C125,10.4 124.67,10.79 123.43,10.79 Z"
            id="Path"
          ></path>
          <path
            d="M131,10.7 L130.85,10.7 C130.389991,10.6945233 130.019967,10.3200418 130.02,9.86 C129.425763,10.484654 128.591505,10.8234573 127.73,10.79 C127.073724,10.8199889 126.43365,10.580761 125.957919,10.1276846 C125.482189,9.67460825 125.212041,9.04695757 125.21,8.39 C125.198753,7.73397156 125.467015,7.10411163 125.947819,6.65765077 C126.428623,6.2111899 127.076601,5.99025589 127.73,6.05 C128.586645,5.9982127 129.423153,6.32331827 130.02,6.94 L130.02,6 C130.02,5.27 129.39,4.78 128.43,4.78 C127.855195,4.77147371 127.29099,4.93516271 126.81,5.25 C126.7085,5.31632278 126.591113,5.35430089 126.47,5.36 C126.209359,5.35270919 125.971791,5.20878616 125.844641,4.98114669 C125.717491,4.75350722 125.719527,4.47575106 125.85,4.25 C126.3,3.53 127.92,3.25 128.76,3.25 C130.41,3.25 131.93,3.91 131.93,5.99 L131.93,9.82 C131.898566,10.3117478 131.492733,10.6957616 131,10.7 L131,10.7 Z M130,8 C129.613903,7.56605268 129.049713,7.33373909 128.47,7.37 C127.72,7.37 127.11,7.76 127.11,8.47 C127.11,9.18 127.72,9.53 128.47,9.53 C129.049713,9.56626091 129.613903,9.33394732 130,8.9 L130,8 Z"
            id="Shape"
          ></path>
          <path
            d="M139.3,10.7 C139.040474,10.7165999 138.785667,10.6249423 138.596183,10.446827 C138.406698,10.2687116 138.29947,10.020056 138.3,9.76 L138.3,6.23 C138.359613,5.86594637 138.244038,5.49509987 137.988152,5.22937224 C137.732266,4.9636446 137.366043,4.83416241 137,4.88 C136.342019,4.87779482 135.725048,5.19936761 135.35,5.74 L135.35,9.74 C135.220078,10.1583192 134.833031,10.4433942 134.395,10.4433942 C133.956969,10.4433942 133.569922,10.1583192 133.44,9.74 L133.44,4.21 C133.442221,3.95168486 133.55065,3.70566795 133.739813,3.52974557 C133.928977,3.35382319 134.182203,3.26350164 134.44,3.28 C134.953625,3.28 135.37,3.69637518 135.37,4.21 L135.37,4.31 C136.010179,3.58630269 136.933869,3.17739649 137.9,3.19 C138.53613,3.11098491 139.173685,3.32543302 139.632752,3.77282842 C140.091819,4.22022383 140.322611,4.85204648 140.26,5.49 L140.26,9.76 C140.260057,10.0128077 140.158281,10.254982 139.977647,10.4318526 C139.797014,10.6087232 139.552751,10.7053789 139.3,10.7 L139.3,10.7 Z"
            id="Path"
          ></path>
          <path
            d="M144.2,10.79 C143.65309,10.8511798 143.108053,10.6602185 142.718917,10.271083 C142.329781,9.88194736 142.13882,9.33690955 142.2,8.79 L142.2,5 L141.73,5 C141.30405,4.95331635 140.981515,4.59350085 140.981515,4.165 C140.981515,3.73649915 141.30405,3.37668365 141.73,3.33 L142.2,3.33 L142.2,2.24 C142.324444,1.81333333 142.715556,1.52 143.16,1.52 C143.604444,1.52 143.995556,1.81333333 144.12,2.24 L144.12,3.36 L144.87,3.36 C145.29595,3.40668365 145.618485,3.76649915 145.618485,4.195 C145.618485,4.62350085 145.29595,4.98331635 144.87,5.03 L144.12,5.03 L144.12,8.3 C144.12,8.87 144.48,9.1 144.78,9.1 L144.94,9.1 C145.164194,9.08236852 145.385089,9.1623597 145.546012,9.31945116 C145.706935,9.47654262 145.792225,9.69544605 145.78,9.92 C145.73,10.4 145.45,10.79 144.2,10.79 Z"
            id="Path"
          ></path>
        </g>
      </g>
    </g>
  </svg>
)

export default SenzaLogo
