import React from "react"
import PropTypes from "prop-types"
import { FormattedMessage } from "react-intl"
import { Wrapper, Button, Menu, MenuItem } from "react-aria-menubutton"
import { User } from "../Icon"

const UserMenu = ({ label, onSelectionChange }) => {
  const handleSelection = (value) => {
    onSelectionChange(value)
  }
  return (
    <Wrapper className="btn-menu" onSelection={handleSelection}>
      <Button className="btn-menu__button">
        <div className="btn-menu__placeholder">
          <div className="btn-menu__icon">
            <User />
          </div>
          <div className="btn-menu__label">{label}</div>
        </div>
        <div className="btn-menu-arrow__wrapper">
          <span className="btn-menu__arrow"></span>
        </div>
      </Button>
      <Menu className="btn-menu__list">
        <ul>
          <li>
            <MenuItem value="logout" className="btn-menu__item -user">
              <FormattedMessage id="logout" />
            </MenuItem>
          </li>
        </ul>
      </Menu>
    </Wrapper>
  )
}

UserMenu.propTypes = {
  label: PropTypes.string.isRequired,
  onSelectionChange: PropTypes.func.isRequired,
}
export default UserMenu
