import React from "react"
import { Link } from "gatsby"

import { getLocalizedPath } from "../../i18n"
import { PageContext } from "../../pageContext"

const isActive = ({ isCurrent }) =>
  isCurrent ? { className: "-active" } : null
const LocalizedLink = ({ to, ...props }) => (
  <PageContext.Consumer>
    {({ locale }) => (
      <Link getProps={isActive} {...props} to={getLocalizedPath(to, locale)} />
    )}
  </PageContext.Consumer>
)

export default LocalizedLink
