import React, { Component } from "react"
import PropTypes from "prop-types"

class CheckBox extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isPristine: true,
      availableErrorsMessage: [...props.errorsMessage],
    }
  }

  validate = value => {
    return this.props.validators
      .map(validator => {
        if (validator(value) === false) {
          return this.state.availableErrorsMessage.filter(
            errorMessage => errorMessage.validator === validator.name
          )[0]
        }
        return null
      })
      .filter(errorMessage => errorMessage)
  }

  handleChange = event => {
    this.props.onChange(event, this.props.id)
    this.props.onError({
      target: {
        value: { id: this.props.id, errors: this.validate(event.target.value) },
      },
    })
    if (this.state.isPristine === true) {
      this.setState({ isPristine: false })
    }
  }

  handleOnBlur = event => {
    this.props.onError({
      target: {
        value: { id: this.props.id, errors: this.validate(event.target.value) },
      },
    })
    this.setState({ isPristine: false })
  }

  render = () => {
    const { id, label, name, value } = this.props

    return (
      <div className="checkbox">
        <label className="checkbox__label" htmlFor={id}>
          <input
            name={name}
            className="checkbox__input"
            type="checkbox"
            checked={value}
            id={id}
            onChange={this.handleChange}
          />
          <div className="checkbox__box"></div>
          <div className="checkbox__text">
            <span className="checkbox__text-label -grey">{label}</span>
          </div>
        </label>
      </div>
    )
  }
}

export const InputPropTypes = {
  id: PropTypes.string.isRequired,
  value: PropTypes.bool,
  name: PropTypes.string.isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  validators: PropTypes.arrayOf(PropTypes.func),
  errorsMessage: PropTypes.arrayOf(
    PropTypes.shape({
      validator: PropTypes.string,
      message: PropTypes.string,
    })
  ),
  onChange: PropTypes.func.isRequired,
  onError: PropTypes.func,
}

CheckBox.propTypes = InputPropTypes

CheckBox.defaultProps = {
  isValid: true,
  onError: () => {},
  validators: [],
  errorsMessage: [],
}
export default CheckBox
